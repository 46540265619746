import React from 'react';
import { useNavigate } from 'react-router-dom';

function About() {
    let navigate = useNavigate();

    const goToEvents = () => {
        navigate('/events');
    };

    return (
        <div className="p-6 flex flex-col items-center">
            <h1 className="font-body text-5xl font-bold">"Was Lauft bi dier i de nöchi?"</h1>
            <h2 className="font-subheading text-3xl mt-2"></h2>
            <p className="font-body text-base mt-2">

            </p>

            <button class="custom-btn btn-6" onClick={goToEvents}>
                <span>Finds Use</span>
            </button>
        </div>
    );
}

export default About;

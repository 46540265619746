// src/components/ContactUs.js
import React from 'react';

function ContactUs() {
    return (
        <section className="text-center p-4">
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <p>Have questions? Reach out to us at business@was-lauft.com.</p>
        </section>
    );
}

export default ContactUs;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function EventsPage() {
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategories, setSelectedCategories] = useState(new Set());
    const [isLoading, setIsLoading] = useState(true);  // State to handle loading effect

    useEffect(() => {
        console.log("Debug: Fetching events data");
        fetch('https://api.was-lauft.com/data')
            .then(response => response.json())
            .then(data => {
                console.log("Debug: Events data fetched", data);
                setEvents(data);
                // Initialize categories from fetched data
                const categories = new Set(data.flatMap(event => event.category.split(', ')));
                const initialCategories = new Set();
                if (categories.has("Hip-Hop / Rap")) {
                    initialCategories.add("Hip-Hop / Rap");
                }
                setSelectedCategories(initialCategories);
                filterEvents('', initialCategories, data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Debug: Error fetching events data", error);
                setIsLoading(false);
            });
    }, []);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        filterEvents(value, selectedCategories, events);
    };

    const handleCategorySelect = (category) => {
        const newSelectedCategories = new Set(selectedCategories);
        if (newSelectedCategories.has(category)) {
            newSelectedCategories.delete(category);
        } else {
            newSelectedCategories.add(category);
        }
        setSelectedCategories(newSelectedCategories);
        filterEvents(searchTerm, newSelectedCategories, events);
    };

    const filterEvents = (searchTerm, categories, eventsData) => {
        const filtered = eventsData.filter(event => {
            const searchMatch = event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                event.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
                event.date.toLowerCase().includes(searchTerm.toLowerCase());
            const categoryMatch = categories.size === 0 || categories.has(event.category);
            return searchMatch && categoryMatch;
        });
        setFilteredEvents(filtered);
    };

    if (isLoading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    const categories = [...new Set(events.flatMap(event => event.category.split(', ')))]; // Extract categories from events

    return (
        <main>
        <div className="p-4 mx-auto max-w-screen-xl">
            <h2 className="text-2xl font-bold mb-4 text-center">Events</h2>
            <input
                type="text"
                placeholder="Search events..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="mb-4 p-2 w-full border rounded"
            />
            <div className="flex flex-wrap gap-2 mb-4">
                {categories.map(category => (
                    <button
                        key={category}
                        onClick={() => handleCategorySelect(category)}
                        className={`px-3 py-1 rounded-full border ${selectedCategories.has(category) ? 'bg-blue-500 text-white' : 'bg-white'}`}
                    >
                        {category}
                    </button>
                ))}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {filteredEvents.map(event => (
                    <Link to={`/events/${event.id}`} key={event.id} className="hover:scale-105 transition-transform duration-300">
                        <div className="bg-white rounded-lg overflow-hidden shadow-lg">
                            <img src={event.image2_url} alt={event.name} className="w-full h-48 object-cover" />
                            <div className="p-4">
                                <h3 className="text-xl font-semibold">{event.name}</h3>
                                <p className="text-gray-600">Date: {event.date}</p>
                                <p className="text-gray-600">Location: {event.location}</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
        </main>
    );
}

export default EventsPage;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

// Fix missing marker issue with Leaflet in React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function EventDetailPage() {
    const { eventId } = useParams();
    const [eventDetails, setEventDetails] = useState(null);

    useEffect(() => {
        console.log("Debug: Fetching event details for event ID", eventId);
        fetch(`https://api.was-lauft.com/data/${eventId}`)
            .then(response => response.json())
            .then(data => {
                console.log("Debug: Event details fetched successfully", data);
                setEventDetails(data);
            })
            .catch(error => console.error("Failed to fetch event details", error));
    }, [eventId]);

    if (!eventDetails) return <div className="flex justify-center items-center h-screen">Loading...</div>;

    return (
        <main>
        <div className="bg-white p-4 md:p-8 max-w-4xl mx-auto shadow-lg rounded-lg">
            <img src={eventDetails.image2_url} alt={eventDetails.name} className="w-full h-auto rounded-t-lg" />
            <div className="p-4">
                <h1 className="text-3xl font-bold text-gray-800">{eventDetails.name}</h1>
                <p className="text-gray-500 mt-2">{eventDetails.category}</p>
                <div className="mt-4">
                    <p className="font-semibold">Location:</p>
                </div>
                <p className="text-sm text-gray-600 mt-1">{eventDetails.address}</p>
                <div className="mt-4">
                    <p className="font-semibold">Date:</p>
                    <p className="text-gray-600">{eventDetails.date} at {eventDetails.time}</p>
                </div>
                <div className="mt-4">
                    <p className="font-semibold">Price:</p>
                    <p className="text-gray-600">{eventDetails.preis}</p>
                </div>
                <a href={eventDetails.url} target="_blank" rel="noopener noreferrer" className="inline-block mt-4 bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600">
                    More details
                </a>
                {eventDetails.latitude && eventDetails.longitude && (
                    <MapContainer center={[eventDetails.latitude, eventDetails.longitude]} zoom={13} scrollWheelZoom={false} className="mt-8 h-64">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[eventDetails.latitude, eventDetails.longitude]}>
                            <Popup>
                                {eventDetails.name} is here!
                            </Popup>
                        </Marker>
                    </MapContainer>
                )}
            </div>
        </div>
        </main>
    );
}

export default EventDetailPage;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <header className="bg-white/80 backdrop-blur-lg py-1 text-center fixed w-full z-10">
            <div className="max-w-7xl mx-auto px-4 flex justify-between items-center">
                {/* Burger Menu */}
                <button 
                    onClick={() => setIsOpen(!isOpen)} 
                    className="relative z-10 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                    <div className={`space-y-2 ${isOpen ? 'open' : ''}`}>
                        <span className={`block h-0.5 w-8 bg-black transform transition duration-500 ease-in-out ${isOpen ? 'rotate-45 translate-y-1.5' : ''}`}></span>
                        <span className={`block h-0.5 w-8 bg-black transform transition duration-500 ease-in-out ${isOpen ? 'opacity-0' : 'opacity-100'}`}></span>
                        <span className={`block h-0.5 w-8 bg-black transform transition duration-500 ease-in-out ${isOpen ? '-rotate-45 -translate-y-3.5' : ''}`}></span>
                    </div>
                </button>

                {/* Site Title */}
                <h1 className="text-xl font-bold flex-1 text-center"></h1>

                {/* Language Dropdown */}
                <div className="relative">
                    <button onClick={() => setDropdownOpen(!dropdownOpen)} className=" p-2 rounded-md focus:outline-none">
                        {dropdownOpen ? 'DE/EN' : 'DE'}
                    </button>
                    {dropdownOpen && (
                        <div className="absolute right-0 mt-2 py-2 w-48 bg-white/30 backdrop-blur-lg rounded-md shadow-xl z-20">
                            <a href="#" className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">
                                DE
                            </a>
                            <a href="#" className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">
                                EN
                            </a>
                        </div>
                    )}
                </div>
            </div>

            {/* Conditional rendering of the menu when burger icon is clicked */}
            {isOpen && (
                <div className="absolute top-full left-0 w-full bg-white/90 backdrop-blur-md p-4 shadow-md">
                    <Link to="/" className="block py-2 text-sm text-gray-700 hover:bg-gray-100 border-b border-gray-200">
                        Home
                    </Link>
                    <Link to="/events" className="block py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Events
                    </Link>
                </div>
            )}


        </header>
    );
}

// src/components/Hero.js
import React from 'react';

function Hero() {
    return (
        <main className="flex-grow flex justify-center items-center">
            <div className="w-full h-0 pb-[56.25%] relative"> {/* Adjust the paddingBottom percentage based on your GIF's aspect ratio */}
                <img src="/HeroGif2.gif" alt="Centered GIF" className="absolute top-0 left-0 w-full h-full object-contain" />
            </div>
        </main>
    );
}

export default Hero;

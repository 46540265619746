// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import EventsPage from './components/EventsPage';
import EventDetailPage from './components/EventDetailPage'; // Import the new component
import Hero from './components/Hero';
import About from './components/About';
import ContactUs from './components/ContactUs';

function App() {
  console.log("Debug: Rendering App component");

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <About />
              <ContactUs />
            </>
          } />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/events/:eventId" element={<EventDetailPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

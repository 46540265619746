import React from 'react';

function Footer() {
    console.log("Debug: Rendering Footer component");

    return (
        <footer className="text-center py-4">
            <p className="text-lg">WasLauft - Coming Soon</p>
        </footer>
    );
}

export default Footer;
